
















































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { FormFieldAttrs } from "@/models/form";
import XButton from "../SimpleButton.vue";

@Component({
  components: { XButton },
  computed: {
    ...mapGetters("user", { userEmail: "email" }),
  },
})
class ExpansionBarWithForm extends Vue {
  [x: string]: any;

  @Prop({ default: false }) readonly show!: boolean;

  @Prop({ default: "" }) readonly formType!: string;
  @Prop({ default: () => ({}) }) readonly fieldAttrs!: FormFieldAttrs;

  @Prop({ default: false }) readonly submitButtonState!: boolean;
  @Prop({ default: false }) readonly submitButtonLoading!: boolean;
  @Prop({ default: "" }) readonly submitButtonText!: string;

  @Prop({ default: false }) readonly disabled!: boolean;

  get content() {
    type MainRowContent = {
      key: string;
      value: string;
    };

    const content: { [rowType: string]: MainRowContent } = {
      email: {
        key: "Электронная почта",
        value: this.userEmail || "",
      },
      password: {
        key: "Пароль",
        value: "",
      },
    };

    return content[this.formType];
  }

  @Emit("click")
  public propagateShowEvt() {
    this.$emit("show", "show");
  }
}

export default ExpansionBarWithForm;
