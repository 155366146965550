import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import {
  AsdPoint,
  AsdObjects,
  AsdPointsByObject
} from "@/models/asd";
import {AskueObjects} from "@/models/hourly-consumption";

@Module({namespaced: true})
class AsdModule extends VuexModule {
  point: AsdObjects | null = null;
  asdObjects: AsdObjects[] | null = null;

  public get sortedPointsByObject(): AsdPointsByObject[] | null {
    const simpleObj: { [x: string]: any } = {};
    if (this.asdObjects) {
      const points = this.asdObjects.reduce((acc, point) => {
        const objectId = point["объект"];
        const asdId = point["идентификаторасд"] ? point["идентификаторасд"] : null;
        const objectName = point["названиеобъекта"];
        const tuName = point["названиету"];
        const puName = point["типсчетчика"];
        const objectLevel = 1;

        if (tuName in acc) {
          acc[tuName].pointList.push(point);
        } else {
          acc[tuName] = {
            objectId,
            asdId,
            objectName,
            objectLevel,
            tuName,
            puName,
            pointList: [point]
          };
        }

        return acc;
      }, simpleObj);

      return Object.values(points).sort(
        (point1, point2) => point1.objectId - point2.objectId
      );
    }
    return null;
  }

  public get pointId(): number | null {
    return this.point && this.point["объект"];
  }

  public get pointLs(): number | null {
    return this.point && this.point["лицевой"];
  }

  public get name(): string | null {
    return this.point && this.point["названиету"]+this.point["названиепу"];
  }

  public get asdObjectsData(): AskueObjects[] | null {
    return this.asdObjects;
  }

  @Mutation
  setPoint(point: AsdObjects | null) {
    this.point = point;
  }

  @Mutation
  setAsdObjects(objects: AsdObjects[] | null) {
    this.asdObjects = objects
  }
}

export default AsdModule;
