import { required, passwordCompare, passwordLength, passwordStrength1, passwordStrength2, passwordStrength3, passwordStrength4 } from "./field-rules";
import { password } from "./field-icons";

export default [
  {
    name: "oldPwd",
    attrs: {
      type: "password",
      label: "Старый пароль",
      required: true,
      appendIcons: password
    },
    rules: [required]
  },
  {
    name: "newPwd",
    attrs: {
      type: "password",
      label: "Новый пароль",
      required: true,
      appendIcons: password
    },
    rules: [required, passwordLength, passwordStrength1, passwordStrength2, passwordStrength3, passwordStrength4]
  },
  {
    name: "newPwdCopy",
    attrs: {
      type: "password",
      label: "Повторите пароль",
      required: true,
      appendIcons: password
    },
    rules: [required, passwordCompare]
  }
];
