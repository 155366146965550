import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import AppConfig from "@/models/app-config";

@Module({ namespaced: true })
class AppConfigModule extends VuexModule {
  appConfig: AppConfig | null = null;

  public get demo() {
    return this.appConfig.demo;
  }
  public get login() {
    return this.appConfig.login;
  }
  public get password() {
    return this.appConfig.password;
  }
  @Mutation
  setAppConfig(appConfig: AppConfig) {
    this.appConfig = appConfig;
  }
}

export default AppConfigModule;
