import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import Environment from "@/models/environment";

@Module({ namespaced: true })
class EnvironmentModule extends VuexModule {
  constants: Environment | null = null;

  @Mutation
  setConstants(constants: Environment) {
    this.constants = constants;
  }
}

export default EnvironmentModule;
