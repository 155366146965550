




import { Component, Vue } from "vue-property-decorator";

@Component
class NotificationsForRoot extends Vue {

}
export default NotificationsForRoot;
