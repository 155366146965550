




















import {Component, Mixins, Prop} from "vue-property-decorator";
import XDialog from "../SimpleDialog.vue";
import AppApiMixin from "../mixins/AppApi.vue";

@Component({
  components: { XDialog },
})
class ModalDialogForIndication extends Mixins(AppApiMixin, XDialog) {
  @Prop({ required: true }) showDialog!: boolean;
  @Prop({ required: true }) readonly zones!: number;
  @Prop({ required: true }) readonly zonesNames!: Array<string>;
  @Prop({ required: true }) readonly rateFlag: Array<boolean>;

  get text() {
    if (this.zones === 1) {
      return "Показания меньше предыдущих";
    } else if (this.rateFlag.filter((item, key) => item == true && key < this.zones).length >= this.zones) {
      return "Показания по всем зонам меньше предыдущих";
    } else {
      let text = "";
      for (let i = 0; i < this.rateFlag.length; i++) {
        if (this.rateFlag[i]) {
          text += "Показание по зоне \"" + this.zonesNames[i] + "\" меньше предыдущего";
        }
      }
      return text;
    }
  }
}
export default ModalDialogForIndication;
