function prepareMoney(number: number): string {
  return Number.isNaN(number) || typeof number === 'undefined'
    ? ""
    : number
        .toLocaleString("ru-Ru", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
        .replace(",", ".");
}

function valueOrDash(value: string): string {
  return !value ? "-" : value;
}

function appealsAcceptedExtensions(): string {
  return ".jpeg,.jpg,.png,.pdf,.doc,.docx,.txt, максимальный размер 3МБ";
}

function agreementAcceptedExtensions(): string {
  return ".pdf,.doc,.docx, максимальный размер 10МБ";
}

export { prepareMoney, valueOrDash, appealsAcceptedExtensions, agreementAcceptedExtensions };
