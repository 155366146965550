import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store/store";
import { Contract, ContractBalance } from "@/models/contract";

@Module({ namespaced: true })
class ContractModule extends VuexModule {
  contract: Contract | null = null;

  get useContract(): boolean {
    return !!this.contract;
  }

  get id(): number | null {
    return this.contract && this.contract["$номерЗаписи"];
  }

  get orgIdOfContractId(): number | null {
    return this.contract && this.contract["организация"];
  }

  get number(): string | null {
    return this.contract && this.contract["номер"];
  }

  get type(): string | null {
    return this.contract && this.contract["видпотребления"];
  }

  get dateStartContract(): string | null {
    return this.contract && this.contract["начало договора"];
  }

  get balance(): number {
    if (this.contract) {
      const contractBalance = store.getters["contracts/balanceList"].find(
        (balance: ContractBalance) => balance["договор"] === this.id
      );

      return contractBalance
        ? contractBalance["сальдо"] + contractBalance["предоплата"]
        : NaN;
    }

    return NaN;
  }

  get inactive(): boolean {
    return this.contract["папки"] == -1;
  }

  get closed(): boolean {
    const dateEnd = new Date(this.contract["окончание"]);
    // @ts-ignore
    if (isNaN(dateEnd)) {
      // Не указана дата расторжения
      return false;
    } else if(dateEnd <= new Date()) {
      // Договор закрыт
      return true;
    }
    // Договор действует
    return false;
  }

  @Mutation
  setContract(contract: Contract) {
    this.contract = contract;
  }
}

export default ContractModule;
