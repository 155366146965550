import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AccountingPoint,
  AccountingPointsPUState,
  MeasuringComplexByAccountingPoint,
  OtherEquipmentByAccountingPoint,
  ParamsByAccountingPoint,
  PlanServicesByAccountingPoint,
  PlanValuesByAccountingPoint,
  HistoryByAccountingPoint,
  DeviceForHistory,
  HistoryOfDevice
} from "@/models/accounting-point";

@Module({ namespaced: true })
class AccountingPointModule extends VuexModule {
  point: AccountingPoint | null = null;
  device: DeviceForHistory | null = null;
  deviceHistory: HistoryOfDevice[] | null = null;
  accountingPointsPU: AccountingPointsPUState | null = null;
  measuringComplex: MeasuringComplexByAccountingPoint[] | null = null;
  otherEquipment: OtherEquipmentByAccountingPoint[] | null = null;
  params: ParamsByAccountingPoint | null = null;
  history: HistoryByAccountingPoint | null = null;
  services: PlanServicesByAccountingPoint[] | null = null;
  planValues: PlanValuesByAccountingPoint[] | null = null;

  public get number(): number | null {
    return this.point && this.point["номер"];
  }

  public get historyByDevice() {
    return this.deviceHistory;
  }

  public get pointId(): number | null {
    return this.point && this.point["лицевой"];
  }

  public get name(): string | null {
    return this.point && this.point["названиету"];
  }

  public get devicePointName(): string | null {
    return this.device && this.device["названиету"];
  }

  public get devicePointNumber(): string | null {
    return this.device && this.device["номерту"];
  }

  public get address(): string | null {
    return this.point && this.point["адрес"];
  }

  public get pointServices(): PlanServicesByAccountingPoint[] | null {
    return this.services;
  }

  public get pointPlanValues(): PlanValuesByAccountingPoint[] | null {
    return this.planValues;
  }

  @Mutation
  setPoint(point: AccountingPoint | null) {
    this.point = point;
  }

  @Mutation
  setMeasuringComplex(
    measuringComplex: MeasuringComplexByAccountingPoint[] | null
  ) {
    this.measuringComplex = measuringComplex;
  }

  @Mutation
  setDevice(device: DeviceForHistory | null) {
    this.device = device;
  }

  @Mutation
  setDeviceHistory(history: any) {
    this.deviceHistory = history;
  }

  @Mutation
  setOtherEquipment(otherEquipment: OtherEquipmentByAccountingPoint[] | null) {
    this.otherEquipment = otherEquipment;
  }

  @Mutation
  setParams(params: ParamsByAccountingPoint | null) {
    this.params = params;
  }

  @Mutation
  setPointHistory(history: HistoryByAccountingPoint | null) {
    this.history = history;
  }

  @Mutation
  setPlanServices(services: PlanServicesByAccountingPoint[] | null) {
    this.services = services;
  }

  @Mutation
  setPlanValues(planValues: PlanValuesByAccountingPoint[] | null) {
    this.planValues = planValues;
  }
}

export default AccountingPointModule;
