var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',[(_vm.useWarning)?_c('card-as-alert',{staticClass:"card_color_warning",attrs:{"imageName":"warning.svg"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Безопасность аккаунта")]},proxy:true},{key:"message",fn:function(){return [_vm._v(" Чтобы защитить свой аккаунт и получить доступ к функционалу личного кабинета, придумайте и задайте новый пароль. ")]},proxy:true}],null,false,2638550963)}):_vm._e(),_c('replacement-card',{on:{"show-dialog":function (email) {
        _vm.actionDialog.payload = email;
        _vm.actionDialog.show = true;
      },"show-notice":function (message) {
        _vm.notice.message = message;
        _vm.notice.show = !_vm.notice.show;
      }}}),_c('confirm-action-dialog',{attrs:{"show-dialog":_vm.actionDialog.show,"payload":_vm.actionDialog.payload,"interval-min":10},on:{"agree":function () {
        _vm.notice.message = "Email успешно изменен на " + (_vm.actionDialog.payload);
        _vm.closeAllDialogs();
        _vm.notice.show = !_vm.notice.show;
      },"disagree":_vm.closeAllDialogs}}),_c('x-notice',{attrs:{"show":_vm.notice.show}},[_vm._v(" "+_vm._s(_vm.notice.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }