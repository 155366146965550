import { digitFloatOrEmpty } from "./field-rules";

export default [
  {
    name: "0",
    attrs: {
      type: "text",
      label: "",
      required: true
    },
    rules: [ digitFloatOrEmpty ]
  },
  {
    name: "1",
    attrs: {
      type: "text",
      label: "",
      required: true
    },
    rules: [ digitFloatOrEmpty ]
  },
  {
    name: "2",
    attrs: {
      type: "text",
      label: "",
      required: true
    },
    rules: [ digitFloatOrEmpty ]
  }
];
