var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',{attrs:{"caption":("История показаний по ПУ № " + (_vm.accountingPoint.device && _vm.accountingPoint.device['заводскойномер'])),"headers":_vm.headers,"items":_vm.items,"use-item-slot":true,"hide-default-footer":false,"disable-pagination":false},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('x-dropdown-with-date-picker',{attrs:{"dates":_vm.dates,"set-default-date":_vm.setDefaultDate},on:{"update:dates":function($event){_vm.dates=$event}}})]},proxy:true},{key:"item",fn:function(ref){
var ref_item = ref.item;
var index = ref_item.index;
var item = ref_item.item;
return [_c('tr',{staticClass:"data-table__text data-table__text_cursor_default"},_vm._l((item),function(value,header){return _c('td',{key:("" + header),class:_vm.tdClassesByHeaders[header]},[(header.endsWith('date'))?[_c('div',[_c('v-icon',{staticClass:"icon icon_size_lg icon_color_green rounded-circle"},[_vm._v(" mdi-counter ")]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(value)))])],1)]:(header.endsWith('dateZa'))?[_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("formatDateMY")(value)))])])]:(header.endsWith('dayZone') && value)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon icon_size_lg rounded-circle"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons[index])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]:[_vm._v(" "+_vm._s(value || "—")+" ")]],2)}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }