











































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import { AccountingPointIndications } from "@/models/accounting-point";
import SimpleCard from "../SimpleCard.vue";
import NewValueForm from "./CountersNewValueForm.vue";
import ContractIcon from "@/components/ContractIcon.vue";
import SimpleCardWithList from "@/components/SimpleCardWithList.vue";
import {mapGetters} from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: {
    SimpleCard,
    ContractIcon,
    NewValueForm,
    SimpleCardWithList
  },
  computed: {
    ...mapGetters({ accountingPoints: "accountingPoints/allPoints" })
  },
})
class EquipmentListCard extends Mixins(AppApiMixin) {
  @Prop({required: true}) readonly equipment!: AccountingPointIndications;

  get disabled() {
    return this.equipment.запретвводапоказаний != 0;
  }

  equipmentPointAddress(id) {
    return (this.accountingPoints[id] != undefined) ? this.accountingPoints[id].адрес : "";
  }

  get counterData() {
    const {типсчетчика, заводскойномер, услуга, тип, тарифность, коэфтр} = this.equipment;

    return [
      {title: 'марка', value: типсчетчика},
      {title: 'заводской номер', value: заводскойномер},
      {title: 'услуга', value: услуга},
      {title: 'тип', value: тип},
      {title: 'тарифность', value: тарифность},
      {title: 'коэф.тр', value: коэфтр},
    ]
  }
}

export default EquipmentListCard;
