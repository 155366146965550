import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AppealsByContract,
  AppealTypes,
  AppealThemes,
  AppealChatMessages,
  AppealCustomFields
} from "@/models/appeals";

@Module({ namespaced: true })
class AppealsModule extends VuexModule {
  [x: string]: any

  listAppeals: AppealsByContract[] | null = null;

  listTypes: AppealTypes[] | null = null;
  listThemes: AppealThemes[] | null = null;

  listAppealHistory: AppealChatMessages[] | null = null;
  listCustomFields: AppealCustomFields[] | null = null;

  currentAppealInfo: [];
  unreadCounterAppeals: [] | null = null;

  // виды обращений
  public get types() {
    return this.listTypes;
  }
  // темы обращений
  public get themes() {
    return this.listThemes;
  }
  // кастомные поля
  public get appealCustomFields() {
    return this.listCustomFields;
  }

  public get getCurrentAppealInfo() {
    return this.currentAppealInfo;
  }
  public get unreadAppealsCount() {
    return this.unreadCounterAppeals;
  }

  @Mutation
  setTypes(types: AppealTypes[]) {
    this.listTypes = types;
  }
  @Mutation
  setThemes(themes: AppealThemes[] | null) {
    this.listThemes = themes;
  }

  @Mutation
  setCustomFields(customFields: AppealCustomFields[] | null) {
    this.listCustomFields = customFields;
  }

  @Mutation
  setAppealsByContract(appealsByContract: AppealsByContract[]) {
    this.listAppeals = appealsByContract;
  }
  @Mutation
  setAppealHistory(appealChat: AppealChatMessages[]) {
    this.listAppealHistory = appealChat;
  }
  @Mutation
  setCurrentAppealInfo(appealInfo: []) {
    this.currentAppealInfo = appealInfo;
  }
  @Mutation
  setUnreadCounterValue(appealId: []) {
    this.unreadCounterAppeals = appealId;
  }

}

export default AppealsModule;