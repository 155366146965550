import { required } from "./field-rules";

export default [
  {
    name: "login",
    attrs: {
      type: "text",
      label: "Логин",
      required: true
    },
    rules: [required]
  }
];
