import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { NotificationsByContract } from "@/models/notifications";


@Module({ namespaced: true })
class NotificationsModule extends VuexModule {
  [x: string]: any

  listNotifications: NotificationsByContract[] | null = null;
  unreadCounterNotifications: number | null = null;

  public get notificationsList() {
    return this.listNotifications;
  }
  public get unreadNotificationsCount() {
    return this.unreadCounterNotifications;
  }

  @Mutation
  setNotificationsByContract(notificationsByContract: NotificationsByContract[]) {
    this.listNotifications = notificationsByContract;
  }
  @Mutation
  setUnreadNotificationsByContract(unreadCounterNotifications: number) {
    this.unreadCounterNotifications = unreadCounterNotifications;
  }
}

export default NotificationsModule;
